.MuiButton-contained.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.toppp {
    vertical-align: top !important;
}

.tablebg {
  background-color: #f2f2f2;
  box-shadow: none;
}

.form {
  display: inline-flex;
}

.submit {
  color: white;
  background-color: #b32800 !important;
}

.table {
  background-color: #fff;
  max-width: 700px;
  border-radius: 8px;
  margin-left: auto;
  margin-right: auto;
  border: none;
}

.field {
  width: 60%;
}

.top {
  align-items: center;
  text-align: center;
  background-color: white;
  width: 200px;
  height: 67px;
  margin-bottom: 20px;
}

.release {
  text-align: center;
  color: #f99c38;
  font-size: 14px;
}

.second {
  margin-top: 20px;
}

.head {
  color: #b32800;
  font-weight: 600;
  font-size: 28px;
  font-family: Montserrat;
  align-items: center;
  text-align: center;
}

.next {
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.head1 {
  color: #b32800;
  font-size: 24px;
  padding-bottom: 50px;
  padding-top: 15px;
  margin-left: -200px;
  font-family: Montserrat;
  align-items: left;
  line-height: 1.2;
  text-align: left;
  vertical-align: top;
}

.head2 {
  color: #000;
  font-size: 17px;
  font-family: Montserrat;
  align-items: left;
  text-align: left;
}

.contributors {
  align-items: left;
  padding-top: 10px;
  font-family: Montserrat;
  color: #000;
  font-size: 18px;
}

.head3 {
  color: #f99c38;
  font-size: 24px;
  font-family: Montserrat;
  font-weight: 600;
  margin-bottom: -20px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  align-items: left;
  text-align: left;
}

.contentheading {
  align-items: left;
  text-decoration: none;
  font-weight: 600;
  font-family: Montserrat;
  color: #3498db;
  font-size: 22px;
}

.subtitle {
  align-items: left;
  text-decoration: none;
  font-weight: 600;
  margin-bottom: -25px !important;
  font-family: Montserrat;
  color: #6d241b;
  font-size: 18px;
}

.questions {
  align-items: left;
  text-decoration: none;
  font-weight: 600;
  font-family: Montserrat;
  color: #3498db;
  font-size: 22px;
}

.contentinner {
  align-items: left;
  vertical-align: top;
  font-family: Montserrat;
  color: #000;
  font-size: 18px;
}

.contentimages {
  align-items: right;
  margin-bottom: 10;
}

.contentcategory {
  align-items: left;
  text-align: center;
  color: #000;
  font-family: Montserrat;
  font-size: 11px;
}

.creator {
  text-align: center;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  align-items: center;
  border: none !important;
}

.links {
  color: #b32800;
}

.icon {
  width: 0.7rem;
  height: 0.7rem;
}

.list {
  padding-top: 10px;
  padding-bottom: 0px;
  margin-top: -10px;
}

.copyright {
  color: grey;
  align-items: center;
  text-align: center;
  font-size: 14px;
  padding-top: 40px;
  padding-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .second {
    margin-top: 10px;
  }

  .head {
    font-size: 24px;
  }

  .release {
    text-align: center;
    color: #f99c38;
    font-size: 12px;
  }

  .next {
    align-items: center;
  }

  .head1 {
    font-size: 16px;
    padding-top: 25px;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: -90px;
  }

  .head2 {
    font-size: 12px;
  }

  .contributors {
    font-size: 14px;
  }

  .head3 {
    font-size: 22px;
  }

  .contentimages {
    width: 90px;
    height: 80px;
  }

  .contentheading {
    font-size: 18px;
  }

  .subtitle {
    font-size: 16px;
  }

  .contentinner {
    font-size: 14px;
  }

  .contentcategory {
    align-items: left;
    font-size: 8px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .creator {
    width: 60px;
    height: 60px;
  }
}

@media only screen and (max-width: 500px) {
  .field {
    width: 65%;
  }

  .submit {
    width: 35%;
  }
}